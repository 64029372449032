import React, {Suspense} from 'react';
import loadable from '@loadable/component';
import {
  clubFilters,
  cupFilters,
  districtFilters,
  districtTransferFilters,
  leagueFilters,
  marketplaceFilters,
  matchFilters,
  playerFilters,
  teamFilters,
} from './stream/streamFilter.const';
import {Redirect} from 'react-router';

// Main top level routes with nested routes
const DistrictPage = loadable(() => import(/* webpackChunkName: "DistrictPage" */ 'app/routes/district/DistrictPage'));
const MatchPage = loadable(() => import(/* webpackChunkName: "MatchPage" */ 'app/routes/match/MatchPage'));
const ClubPage = loadable(() => import(/* webpackChunkName: "ClubPage" */ 'app/routes/club/ClubPage'));
const CompetitionPage = loadable(() =>
  import(/* webpackChunkName: "CompetitionPage" */ 'app/routes/competition/CompetitionPage')
);
const CupPage = loadable(() => import(/* webpackChunkName: "CupPage" */ 'app/routes/cup/CupPage'));
const LeaguePage = loadable(() => import(/* webpackChunkName: "LeaguePage" */ 'app/routes/league/LeaguePage'));
const MatchCalendarPage = loadable(() =>
  import(/* webpackChunkName: "MatchCalendarPage" */ 'app/routes/matchCalendar/MatchCalendarPage')
);
const PlayerPage = loadable(() => import(/* webpackChunkName: "PlayerPage" */ 'app/routes/player/PlayerPage'));
const RefereeGroupPage = loadable(() =>
  import(/* webpackChunkName: "RefereeGroupPage" */ 'app/routes/refereeGroup/RefereeGroupPage')
);
const TeamPage = loadable(() => import(/* webpackChunkName: "TeamPage" */ 'app/routes/team/TeamPage'));

// Additional top level routes and nested routes used by main top level routes
const StartPage = loadable(() => import(/* webpackChunkName: "StartPage" */ 'app/routes/start/StartPage'));
const CompetitionCrawlerPage = loadable(() =>
  import(/* webpackChunkName: "CompetitionCrawlerPage" */ 'app/routes/competition/CompetitionCrawlerPage')
);
const RegionSelectPage = loadable(() =>
  import(/* webpackChunkName: "RegionSelectPage" */ 'app/routes/region/RegionSelectPage')
);
const ClubContactDetailPage = loadable(() =>
  import(/* webpackChunkName: "ClubContactDetailPage" */ 'app/routes/club/ClubContactDetailPage')
);
const TeamPlayersPage = loadable(() =>
  import(/* webpackChunkName: "TeamPlayersPage" */ 'app/routes/team/TeamPlayersPage')
);
const TeamMatchesPage = loadable(() =>
  import(/* webpackChunkName: "TeamMatchesPage" */ 'app/routes/team/TeamMatchesPage')
);
const TeamNewsPage = loadable(() => import(/* webpackChunkName: "TeamNewsPage" */ 'app/routes/team/TeamNewsPage'));
const TeamTransferPage = loadable(() =>
  import(/* webpackChunkName: "TeamTransferPage" */ 'app/routes/team/TeamTransferPage')
);
const TeamPlayerStatsPage = loadable(() =>
  import(/* webpackChunkName: "TeamPlayerStatsPage" */ 'app/routes/team/TeamPlayerStatsPage')
);
const TeamHistoryPage = loadable(() =>
  import(/* webpackChunkName: "TeamHistoryPage" */ 'app/routes/team/TeamHistoryPage')
);
const ClubTeamsPage = loadable(() => import(/* webpackChunkName: "ClubTeamsPage" */ 'app/routes/club/ClubTeamsPage'));
const ClubMatchesPage = loadable(() =>
  import(/* webpackChunkName: "ClubMatchesPage" */ 'app/routes/club/ClubMatchesPage')
);
const ClubNewsPage = loadable(() => import(/* webpackChunkName: "ClubNewsPage" */ 'app/routes/club/ClubNewsPage'));
const ClubContactsPage = loadable(() =>
  import(/* webpackChunkName: "ClubContactsPage" */ 'app/routes/club/ClubContactsPage')
);
const PlayerProfilePage = loadable(() =>
  import(/* webpackChunkName: "PlayerProfilePage" */ 'app/routes/player/PlayerProfilePage')
);
const PlayerNewsPage = loadable(() =>
  import(/* webpackChunkName: "PlayerNewsPage" */ 'app/routes/player/PlayerNewsPage')
);
const PlayerRankingsPage = loadable(() =>
  import(/* webpackChunkName: "PlayerRankingsPage" */ 'app/routes/player/PlayerRankingsPage')
);
const CompetitionDefaultPage = loadable(() =>
  import(/* webpackChunkName: "CompetitionDefaultPage" */ 'app/routes/competition/CompetitionDefaultPage')
);
const LeagueAlltimePage = loadable(() =>
  import(/* webpackChunkName: "LeagueAlltimePage" */ 'app/routes/league/LeagueAlltimePage')
);

const LeagueSpectatorsPage = loadable(() =>
  import(/* webpackChunkName: "LeagueSpectatorsPage" */ 'app/routes/league/LeagueSpectatorsPage')
);
const LeagueScorersPage = loadable(() =>
  import(/* webpackChunkName: "LeagueScorersPage" */ 'app/routes/league/LeagueScorersPage')
);
const LeagueTopElevenPage = loadable(() =>
  import(/* webpackChunkName: "LeagueTopElevenPage" */ 'app/routes/league/LeagueTopElevenPage')
);
const LeagueNewsPage = loadable(() =>
  import(/* webpackChunkName: "LeagueNewsPage" */ 'app/routes/league/LeagueNewsPage')
);

const LeagueMatchesPage = loadable(() =>
  import(/* webpackChunkName: "LeagueMatchesPage" */ 'app/routes/league/LeagueMatchesPage')
);

const LeagueStandingPage = loadable(() =>
  import(/* webpackChunkName: "LeagueStandingPage" */ 'app/routes/league/LeagueStandingPage')
);

const LeagueRankingsPage = loadable(() =>
  import(/* webpackChunkName: "LeagueRankingsPage" */ 'app/routes/league/LeagueRankingsPage')
);

const LeagueHistoryPage = loadable(() =>
  import(/* webpackChunkName: "LeagueHistoryPage" */ 'app/routes/league/LeagueHistoryPage')
);

const CupScorersPage = loadable(() => import(/* webpackChunkName: "CupScorersPage" */ 'app/routes/cup/CupScorersPage'));

const CupOverviewPage = loadable(() =>
  import(/* webpackChunkName: "CupOverviewPage" */ 'app/routes/cup/CupOverviewPage')
);

const CupNewsPage = loadable(() => import(/* webpackChunkName: "CupNewsPage" */ 'app/routes/cup/CupNewsPage'));

const CupRankingPage = loadable(() =>
  import(/* webpackChunkName: "CupRankingPage" */ 'app/routes/cup/CupRankingsPage')
);

const CupHistoryPage = loadable(() => import(/* webpackChunkName: "CupHistoryPage" */ 'app/routes/cup/CupHistoryPage'));

const MatchCalendarDefaultPage = loadable(() =>
  import(/* webpackChunkName: "MatchCalendarDefaultPage" */ 'app/routes/matchCalendar/MatchCalendarDefaultPage')
);

const MatchDuelsPage = loadable(() =>
  import(/* webpackChunkName: "MatchDuelsPage" */ 'app/routes/match/MatchDuelsPage')
);

const DistrictNewsPage = loadable(() =>
  import(/* webpackChunkName: "DistrictNewsPage" */ 'app/routes/district/DistrictNewsPage')
);

const DistrictMainPage = loadable(() =>
  import(/* webpackChunkName: "DistrictMainPage" */ 'app/routes/district/DistrictMainPage')
);

const DistrictAllNewsPage = loadable(() =>
  import(/* webpackChunkName: "DistrictAllNewsPage" */ 'app/routes/district/DistrictAllNewsPage')
);

const DistrictMarketplacePage = loadable(() =>
  import(/* webpackChunkName: "DistrictMarketplacePage" */ 'app/routes/district/DistrictMarketplacePage')
);

const DistrictMarketplaceDetailPage = loadable(() =>
  import(/* webpackChunkName: "DistrictMarketplaceDetailPage" */ 'app/routes/district/DistrictMarketplaceDetailPage')
);

const DistrictTransfersPage = loadable(() =>
  import(/* webpackChunkName: "DistrictTransfersPage" */ 'app/routes/district/DistrictTransfersPage')
);

const RefereePage = loadable(() => import(/* webpackChunkName: "RefereePage" */ 'app/routes/referee/RefereePage'));

const RefereeGroupSeasonPage = loadable(() =>
  import(/* webpackChunkName: "RefereeGroupSeasonPage" */ 'app/routes/refereeGroup/RefereeGroupSeasonPage')
);

const RefereeGroupHistoryPage = loadable(() =>
  import(/* webpackChunkName: "RefereeGroupHistoryPage" */ 'app/routes/refereeGroup/RefereeGroupHistoryPage')
);

const RefereeGroupCommitteePage = loadable(() =>
  import(/* webpackChunkName: "RefereeGroupCommitteePage" */ 'app/routes/refereeGroup/RefereeGroupCommitteePage')
);

const MatchTickerPage = loadable(() =>
  import(/* webpackChunkName: "MatchTickerPage" */ 'app/routes/match/MatchTickerPage')
);

const MatchLineUpPage = loadable(() =>
  import(/* webpackChunkName: "MatchLineUpPage" */ 'app/routes/match/MatchLineUpPage')
);

const MatchInfoPage = loadable(() => import(/* webpackChunkName: "MatchInfoPage" */ 'app/routes/match/MatchInfoPage'));

const MatchStandingPage = loadable(() =>
  import(/* webpackChunkName: "MatchStandingPage" */ 'app/routes/match/MatchStandingPage')
);

const SettingsPage = loadable(() =>
  import(/* webpackChunkName: "SettingsPage" */ 'app/routes/settings/SettingsWrapperPage')
);

const DeviceManagementPage = loadable(() =>
  import(/* webpackChunkName: "DeviceManagementPage" */ 'app/routes/settings/DeviceManagementWrapperPage')
);

const FollowingSettingsPage = loadable(() =>
  import(/* webpackChunkName: "FollowingSettingsPage" */ 'app/routes/settings/FollowSettingsWrapperPage')
);

const DevToolsPage = loadable(() =>
  import(/* webpackChunkName: "DevToolsPage" */ 'app/routes/settings/DevToolsPageWrapper')
);

const ExperimentalPage = loadable(() =>
  import(/* webpackChunkName: "ExperimentalPage" */ 'app/routes/settings/ExperimentalPageWrapper')
);

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ 'app/routes/about/AboutPage'));

const AboutDetailPage = loadable(() =>
  import(/* webpackChunkName: "AboutDetailPage" */ 'app/routes/about/AboutDetailPage')
);

const NewsPage = loadable(() => import(/* webpackChunkName: "NewsPage" */ 'app/routes/news/NewsPage'));

const SearchPage = loadable(() => import(/* webpackChunkName: "Search" */ 'app/routes/search/SearchPage'));

const PhotosPage = loadable(() => import(/* webpackChunkName: "PhotosPage" */ 'app/routes/photos/PhotosPage'));

const TvPlaylistPage = loadable(() => import(/* webpackChunkName: "TvPlaylistPage" */ 'app/routes/tv/TvPlaylistPage'));

const MyMatchesPage = loadable(() =>
  import(/* webpackChunkName: "MyMatchesPage" */ 'app/routes/matchCalendar/myMatches/MyMatchesWrapperPage')
);

const NotFoundPage = loadable(() => import(/* webpackChunkName: "NotFoundPage" */ 'app/routes/error/NotFoundPage'));

const joinFilters = filterList => filterList.map(filter => filter.param).join('|');

const getLazyComponent = Component => {
  return {
    render: props => (
      <Suspense fallback={<div />}>
        <Component {...props} />
      </Suspense>
    ),
  };
};

const routes = [
  {
    path: '/',
    strict: true,
    exact: true,
    ...getLazyComponent(StartPage),
  },
  {
    path: '/start',
    strict: true,
    exact: true,
    ...getLazyComponent(StartPage),
  },
  {
    path: '/competitions',
    ...getLazyComponent(CompetitionCrawlerPage),
    strict: true,
    exact: true,
  },
  {
    path: '/select/:regionSlug([a-z-]*)?',
    ...getLazyComponent(RegionSelectPage),
    exact: true,
  },
  {
    path: '/tv/match/:matchPlaylistSlug/:videoSceneSlug?',
    ...getLazyComponent(TvPlaylistPage),
    exact: true,
  },
  {
    path: '/photos/:photosSlug',
    ...getLazyComponent(PhotosPage),
    exact: true,
  },
  {
    path: '/contact/:contactSlug',
    ...getLazyComponent(ClubContactDetailPage),
    exact: true,
  },
  {
    path: '/team/:teamSlug/cards',
    exact: true,
    render: props => (
      <Suspense fallback={<div />}>
        <Redirect to={`/team/${props.match.params.teamSlug}/playerstats`} />
      </Suspense>
    ),
  },
  {
    path: '/team/:teamSlug/scorers',
    exact: true,
    render: props => (
      <Suspense fallback={<div />}>
        <Redirect to={`/team/${props.match.params.teamSlug}/playerstats`} />
      </Suspense>
    ),
  },
  {
    path: '/team/:teamSlug',
    component: TeamPage,
    routes: [
      {
        path: '/team/:teamSlug',
        exact: true,
        strict: true,
        ...getLazyComponent(TeamPlayersPage),
      },
      {
        path: '/team/:teamSlug/matches',
        exact: true,
        strict: true,
        ...getLazyComponent(TeamMatchesPage),
      },
      {
        path: `/team/:teamSlug/news/:categories(${joinFilters(teamFilters)})?`,
        exact: true,
        strict: true,
        ...getLazyComponent(TeamNewsPage),
      },
      {
        path: '/team/:teamSlug/transfers',
        exact: true,
        strict: true,
        ...getLazyComponent(TeamTransferPage),
      },
      {
        path: '/team/:teamSlug/rankings',
        exact: true,
        strict: true,
        render: props => (
          <Suspense fallback={<div />}>
            <Redirect to={`/team/${props.match.params.teamSlug}/playerstats`} />
          </Suspense>
        ),
      },
      {
        path: '/team/:teamSlug/playerstats/:type?',
        exact: true,
        strict: true,
        ...getLazyComponent(TeamPlayerStatsPage),
      },
      {
        path: '/team/:teamSlug/history',
        exact: true,
        strict: true,
        ...getLazyComponent(TeamHistoryPage),
      },
      {
        path: '/team/:teamSlug*',
        render: () => (
          <Suspense fallback={<div />}>
            <NotFoundPage id='not-found-page' top='7.5rem' />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/club/:clubSlug',
    exact: false,
    component: ClubPage,
    routes: [
      {
        path: `/club/:clubSlug/:categories(${joinFilters(clubFilters)})?`,
        exact: true,
        strict: true,
        ...getLazyComponent(ClubNewsPage),
      },
      {
        path: '/club/:clubSlug/teams',
        exact: true,
        strict: true,
        ...getLazyComponent(ClubTeamsPage),
      },
      {
        path: '/club/:clubSlug/matches',
        exact: true,
        strict: true,
        ...getLazyComponent(ClubMatchesPage),
      },
      {
        path: '/club/:clubSlug/contacts',
        exact: true,
        strict: true,
        ...getLazyComponent(ClubContactsPage),
      },
      {
        path: '/club/:clubSlug*',
        render: () => (
          <Suspense fallback={<div />}>
            <NotFoundPage id='not-found-page' top='7.5rem' />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/player/:playerSlug',
    exact: false,
    component: PlayerPage,
    routes: [
      {
        path: '/player/:playerSlug',
        exact: true,
        strict: true,
        ...getLazyComponent(PlayerProfilePage),
      },
      {
        path: '/player/:playerSlug/rankings',
        exact: true,
        strict: true,
        ...getLazyComponent(PlayerRankingsPage),
      },
      {
        path: `/player/:playerSlug/news/:categories(${joinFilters(playerFilters)})?`,
        exact: true,
        strict: true,
        ...getLazyComponent(PlayerNewsPage),
      },
      {
        path: '/player/:playerSlug*',
        render: () => (
          <Suspense fallback={<div />}>
            <NotFoundPage id='not-found-page' top='7.5rem' />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/region/:districtSlug/competitions',
    component: CompetitionPage,
    routes: [
      {
        path: '/region/:districtSlug/competitions/1/ageGroup/m',
        exact: true,
        strict: true,
        // Redirect only working for CSR - SSR use case handled via staticRedirects-Middleware
        render: props => (
          <Suspense fallback={<div />}>
            <Redirect to={`/region/${props.match.params.districtSlug}/competitions`} />
          </Suspense>
        ),
      },
      {
        path: '/region/:districtSlug/competitions/:params(\\d/ageGroup/[a-z0-9-]*)?',
        exact: true,
        strict: true,
        ...getLazyComponent(CompetitionDefaultPage),
      },
      {
        path: '/region/:districtSlug/competitions*',
        render: () => (
          <Suspense fallback={<div />}>
            <NotFoundPage id='not-found-page' top='7.5rem' />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/league/:leagueSlug/standing/alltime',
    ...getLazyComponent(LeagueAlltimePage),
    exact: true,
  },
  {
    path: '/league/:leagueSlug/season/:seasonSlug/powerranking/:matchDay',
    render: props => (
      <Suspense fallback={<div />}>
        <Redirect
          to={`/league/${props.match.params.leagueSlug}/season/${props.match.params.seasonSlug}/standing/form`}
        />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: '/league/:leagueSlug/powerranking/:matchDay',
    render: props => (
      <Suspense fallback={<div />}>
        <Redirect to={`/league/${props.match.params.leagueSlug}/standing/form`} />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: '/league/:leagueSlug/season/:seasonSlug/spectators',
    ...getLazyComponent(LeagueSpectatorsPage),
    season: true,
    exact: true,
  },
  {
    path: '/league/:leagueSlug/spectators',
    ...getLazyComponent(LeagueSpectatorsPage),
    exact: true,
  },
  {
    path: '/league/:leagueSlug/season/:seasonSlug/scorers',
    ...getLazyComponent(LeagueScorersPage),
    season: true,
    exact: true,
  },
  {
    path: '/league/:leagueSlug/scorers',
    ...getLazyComponent(LeagueScorersPage),
    exact: true,
  },
  {
    path: '/league/:leagueSlug/season/:seasonSlug/topeleven/:number',
    ...getLazyComponent(LeagueTopElevenPage),
    exact: true,
  },
  {
    path: '/league/:leagueSlug/topeleven/:number',
    ...getLazyComponent(LeagueTopElevenPage),
    exact: true,
  },
  {
    path: '/league/:leagueSlug/season/:seasonSlug',
    component: LeaguePage,
    season: true,
    exact: false,
    routes: [
      {
        path: '/league/:leagueSlug/season/:seasonSlug/matches',
        exact: true,
        ...getLazyComponent(LeagueMatchesPage),
      },
      {
        path: '/league/:leagueSlug/season/:seasonSlug/standing/:type?',
        exact: false,
        ...getLazyComponent(LeagueStandingPage),
      },
      {
        path: '/league/:leagueSlug/season/:seasonSlug/rankings',
        exact: true,
        ...getLazyComponent(LeagueRankingsPage),
      },
      {
        path: `/league/:leagueSlug/season/:seasonSlug/:categories(${joinFilters(leagueFilters)})?`,
        exact: true,
        strict: true,
        ...getLazyComponent(LeagueNewsPage),
      },
      {
        path: '/league/:leagueSlug/season/:seasonSlug*',
        render: () => (
          <Suspense fallback={<div />}>
            <NotFoundPage id='not-found-page' top='7.5rem' />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/league/:leagueSlug',
    component: LeaguePage,
    season: false,
    exact: false,
    routes: [
      {
        path: '/league/:leagueSlug/matches',
        exact: true,
        ...getLazyComponent(LeagueMatchesPage),
      },
      {
        path: '/league/:leagueSlug/standing/:type?',
        exact: false,
        ...getLazyComponent(LeagueStandingPage),
      },
      {
        path: '/league/:leagueSlug/rankings',
        exact: true,
        ...getLazyComponent(LeagueRankingsPage),
      },
      {
        path: '/league/:leagueSlug/history',
        exact: true,
        ...getLazyComponent(LeagueHistoryPage),
      },
      {
        path: `/league/:leagueSlug/:categories(${joinFilters(leagueFilters)})?`,
        exact: true,
        strict: true,
        ...getLazyComponent(LeagueNewsPage),
      },
      {
        path: '/league/:leagueSlug*',
        render: () => (
          <Suspense fallback={<div />}>
            <NotFoundPage id='not-found-page' top='7.5rem' />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/cup/:cupSlug/season/:seasonSlug/scorers',
    ...getLazyComponent(CupScorersPage),
    exact: true,
  },
  {
    path: '/cup/:cupSlug/scorers',
    ...getLazyComponent(CupScorersPage),
    exact: true,
  },
  {
    path: '/cup/:cupSlug/season/:seasonSlug',
    component: CupPage,
    season: true,
    exact: false,
    routes: [
      {
        path: '/cup/:cupSlug/season/:seasonSlug/matches',
        exact: true,
        strict: true,
        ...getLazyComponent(CupOverviewPage),
      },
      {
        path: '/cup/:cupSlug/season/:seasonSlug/rankings',
        exact: true,
        strict: true,
        ...getLazyComponent(CupRankingPage),
      },
      {
        path: `/cup/:cupSlug/season/:seasonSlug/:categories(${joinFilters(cupFilters)})?`,
        exact: true,
        strict: true,
        ...getLazyComponent(CupNewsPage),
      },
      {
        path: '/cup/:cupSlug/season/:seasonSlug*',
        render: () => (
          <Suspense fallback={<div />}>
            <NotFoundPage id='not-found-page' top='7.5rem' />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/cup/:cupSlug',
    component: CupPage,
    season: false,
    exact: false,
    routes: [
      {
        path: '/cup/:cupSlug/matches',
        exact: true,
        strict: true,
        ...getLazyComponent(CupOverviewPage),
      },
      {
        path: '/cup/:cupSlug/rankings',
        exact: true,
        strict: true,
        ...getLazyComponent(CupRankingPage),
      },
      {
        path: '/cup/:cupSlug/history',
        exact: true,
        strict: true,
        ...getLazyComponent(CupHistoryPage),
      },
      {
        path: `/cup/:cupSlug/:categories(${joinFilters(cupFilters)})?`,
        exact: true,
        strict: true,
        ...getLazyComponent(CupNewsPage),
      },
      {
        path: '/cup/:cupSlug*',
        render: () => (
          <Suspense fallback={<div />}>
            <NotFoundPage id='not-found-page' top='7.5rem' />
          </Suspense>
        ),
      },
    ],
  },
  {
    // Filters need to be names as 'categories' otherwise FilterMenu.js can not restore the selected value
    path: `/region/:districtSlug/matches/:categories(${joinFilters(matchFilters)})?`,
    component: MatchCalendarPage,
    context: '/region',
    routes: [
      {
        path: `/region/:districtSlug/matches/:categories(${joinFilters(matchFilters)})?`,
        ...getLazyComponent(MatchCalendarDefaultPage),
        exact: true,
      },
      {
        path: '/region/:districtSlug/matches*',
        render: () => (
          <Suspense fallback={<div />}>
            <NotFoundPage id='not-found-page' top='7.5rem' />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/region/:districtSlug/marketplace/detail/:detailId',
    exact: true,
    ...getLazyComponent(DistrictMarketplaceDetailPage),
  },
  {
    path: `/test_region/:districtSlug/news`,
    exact: true,
    strict: true,
    ...getLazyComponent(DistrictAllNewsPage),
  },
  {
    path: '/test_region/:districtSlug',
    exact: false,
    component: DistrictPage,
    routes: [
      {
        path: `/test_region/:districtSlug`,
        exact: true,
        strict: true,
        ...getLazyComponent(DistrictMainPage),
      },
    ],
  },
  {
    path: '/region/:districtSlug',
    exact: false,
    component: DistrictPage,
    routes: [
      {
        path: `/region/:districtSlug/transfers`,
        exact: true,
        strict: true,
        ...getLazyComponent(DistrictTransfersPage),
      },
      {
        path: `/region/:districtSlug/marketplace/:categories(${joinFilters(marketplaceFilters)})?`,
        exact: true,
        strict: true,
        ...getLazyComponent(DistrictMarketplacePage),
      },
      {
        path: `/region/:districtSlug/:categories(${joinFilters(districtTransferFilters)})`,
        exact: true,
        strict: true,
        render: props => (
          <Suspense fallback={<div />}>
            <Redirect to={`/region/${props.match.params.districtSlug}/transfers`} />
          </Suspense>
        ),
      },
      {
        path: `/region/:districtSlug/:categories(${joinFilters(districtFilters)})`,
        exact: true,
        strict: true,
        render: props => (
          <Suspense fallback={<div />}>
            <Redirect to={`/region/${props.match.params.districtSlug}`} />
          </Suspense>
        ),
      },
      {
        path: `/region/:districtSlug`,
        exact: true,
        strict: true,
        ...getLazyComponent(DistrictNewsPage),
      },
      {
        path: '/region/:districtSlug*',
        render: () => (
          <Suspense fallback={<div />}>
            <NotFoundPage id='not-found-page' top='7.5rem' />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/referee/:refereeSlug',
    exact: true,
    strict: true,
    ...getLazyComponent(RefereePage),
  },
  {
    path: '/referee-group/:groupSlug',
    exact: false,
    component: RefereeGroupPage,
    routes: [
      {
        path: '/referee-group/:groupSlug',
        exact: true,
        strict: true,
        ...getLazyComponent(RefereeGroupSeasonPage),
      },
      {
        path: '/referee-group/:groupSlug/history',
        exact: true,
        strict: true,
        ...getLazyComponent(RefereeGroupHistoryPage),
      },
      {
        path: '/referee-group/:groupSlug/committee',
        exact: true,
        strict: true,
        ...getLazyComponent(RefereeGroupCommitteePage),
      },
      {
        path: '/referee-group/:groupSlug*',
        render: () => (
          <Suspense fallback={<div />}>
            <NotFoundPage id='not-found-page' top='7.5rem' />
          </Suspense>
        ),
      },
    ],
  },
  {
    // Check for subroutes in MatchPage to handle not found page for unknown routes
    // Handling not found page as single route for nested routes is not working for match page due to react error
    path: '/match/:matchSlug',
    component: MatchPage,
    routes: [
      {
        path: '/match/:matchSlug',
        exact: true,
        strict: true,
        ...getLazyComponent(MatchTickerPage),
      },
      {
        path: '/match/:matchSlug/lineup',
        exact: true,
        strict: true,
        ...getLazyComponent(MatchLineUpPage),
      },
      {
        path: '/match/:matchSlug/info',
        exact: true,
        strict: true,
        ...getLazyComponent(MatchInfoPage),
      },
      {
        path: '/match/:matchSlug/standing',
        exact: true,
        strict: true,
        ...getLazyComponent(MatchStandingPage),
      },
      {
        path: '/match/:matchSlug/duels',
        exact: true,
        strict: true,
        ...getLazyComponent(MatchDuelsPage),
      },
    ],
  },
  {
    path: '/my/following/devices',
    exact: true,
    strict: true,
    ...getLazyComponent(DeviceManagementPage),
  },
  {
    path: '/my/following',
    exact: true,
    strict: true,
    ...getLazyComponent(FollowingSettingsPage),
  },
  {
    path: '/my/settings',
    exact: true,
    ...getLazyComponent(SettingsPage),
  },
  {
    path: '/my/devtools',
    exact: true,
    ...getLazyComponent(DevToolsPage),
  },
  {
    path: '/my/experimental',
    exact: true,
    ...getLazyComponent(ExperimentalPage),
  },
  {
    path: '/about',
    exact: true,
    ...getLazyComponent(AboutPage),
  },
  {
    path: '/about/:categorySlug/:regionSlug?',
    exact: true,
    ...getLazyComponent(AboutDetailPage),
  },
  {
    path: '/my/matches',
    component: MatchCalendarPage,
    context: '/my',
    routes: [
      {
        path: '/my/matches',
        ...getLazyComponent(MyMatchesPage),

        exact: true,
      },
    ],
  },
  {
    path: '/news/:newsSlug',
    exact: true,
    ...getLazyComponent(NewsPage),
  },
  {
    path: '/search',
    exact: true,
    strict: true,
    ...getLazyComponent(SearchPage),
  },
  {
    path: '*',
    render: () => (
      <Suspense fallback={<div />}>
        <NotFoundPage id='not-found-page' />
      </Suspense>
    ),
  },
];

export default routes;
