import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectCurrentUser} from 'app/hooks/reduxCreateSelectorHooks';

// Michi, Basti, Simone, Sepp, Luis, Ferdi
const experimentalUserIds = [1, 438, 228061, 275832, 259300, 279510];

const useExperimental = () => {
  const {fuPaUser} = useSelector(selectCurrentUser);

  const checkAccess = () => {
    return experimentalUserIds.includes(fuPaUser?.id) || fuPaUser?.permissions?.switchEnvironment;
  };

  const [experimental, setExperimental] = useState(checkAccess);

  useEffect(() => {
    setExperimental(checkAccess);
  }, [fuPaUser]);

  return experimental;
};

const useIsGuestUser = () => {
  const {initializing, user} = useSelector(selectCurrentUser);

  const checkAccess = () => {
    return !initializing && !user?.uid;
  };
  const [isGuestUser, setIsGuestUser] = useState(checkAccess());

  useEffect(() => {
    setIsGuestUser(checkAccess());
  }, [initializing, user]);

  return isGuestUser;
};

const useWebInterstitialFlag = () => {
  const {experimentalFlags} = useSelector(selectCurrentUser);
  const isGuestUser = useIsGuestUser();

  const checkAccess = () => {
    return experimentalFlags?.includes('web_interstitial') || isGuestUser;
  };

  // Skip for Development
  const [webInterstitial, setWebInterstitial] = useState(checkAccess());

  useEffect(() => {
    setWebInterstitial(checkAccess());
  }, [experimentalFlags]);

  return webInterstitial;
};
export {useExperimental, useWebInterstitialFlag};
