import React, {useEffect} from 'react';
import styled from 'styled-components';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import {Content} from 'app/styles/content';
import {Helmet} from 'react-helmet-async';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {FPLink} from 'app/components/links/link';
import {captureSentryException} from 'app/services/sentryLogging';

const Wrapper = styled(Content)<{top: string}>`
  &&& {
    padding: ${props => (props.top ? props.top : SIZES[72])} ${SIZES['16']};
    height: calc(100vh - ${props => (props.top ? props.top : SIZES[72])});
    margin: auto;
  }
`;

const Text = styled(Typography)`
  &&& {
    color: ${COLORS.darkGrey};
  }
`;

const TextLink = styled(FPLink)`
  &&& {
    text-decoration: none;
    color: ${COLORS.fupaSecondary};
    cursor: pointer;

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }
`;

const ForbiddenPage = ({top}) => {
  useEffect(() => {
    captureSentryException(new Error('Rate Limit of API fired.'), {extra: {location: location.pathname}});
  }, []);

  return (
    <Wrapper id={'http-error-403'} top={top}>
      <Helmet title={`Anfrage wurde blockiert${TITLE_POSTFIX}`} />
      <Typography variant='display2'>Deine Anfrage wurde blockiert.</Typography>
      <Text variant='subheading' component='p'>
        Erfahre hier, was der Grund dafür sein kann:{' '}
        <TextLink to='https://support.fupa.net/support/solutions/articles/75000117879-blockierte-aufrufe-fehlermeldung-fehlerseite-'>
          FAQ - Blockierte Aufrufe
        </TextLink>
      </Text>
      <Text variant='subheading' component='p'>
        Nutze die Suchfunktion oder gehe zur
        <TextLink to='/'> FuPa Startseite️</TextLink>.
      </Text>
    </Wrapper>
  );
};

export default ForbiddenPage;
