import {windowIsDefined} from 'app/helpers/windowIsDefined';

const catchDataError = error => {
  let errorData;
  if (error.response) {
    if (error.response.status === 410) {
      errorData = {
        message: error.message,
        status: error.response.status,
      };
      if (error.config?.url?.startsWith('/profiles/')) {
        errorData.redirectSlug = error.response?.data?.currentSlug;
      }
    } else {
      if (error.response.data?.error) {
        errorData = {
          message: error.response.data.error.message,
          status: error.response.status,
        };
      } else {
        errorData = {
          message: error.message,
          status: error.response.status,
        };
      }
    }
  } else if (error.request && error.request.statusText) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    errorData = {
      message: error.request.statusText,
      status: error.request.status,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    errorData = {
      message: error.message,
      status: error.status,
    };
  }
  return errorData;
};

const checkApiRedirect = (
  apiEntitySlug,
  urlEntitySlug,
  apiSeason = null,
  urlSeasonSlug = null,
  getState = null,
  activeCompetitionSeason = false,
  seasonUrlPosition = 3
) => {
  const pathname = getUniversalPathname(getState);
  let url = pathname;
  const isHistory = url.includes('/history');
  const isNotActiveSeason = apiSeason && !activeCompetitionSeason;
  const isCurrentSeasonWithArchiveUrl = urlSeasonSlug && activeCompetitionSeason;
  let temporaryRedirect = false;

  if (apiEntitySlug !== urlEntitySlug) {
    url = pathname.replace(urlEntitySlug, apiEntitySlug);
  }
  if (!isHistory && isNotActiveSeason && apiSeason.slug !== urlSeasonSlug) {
    const paths = url.split('/');
    paths.splice(seasonUrlPosition, 0, 'season');
    paths.splice(seasonUrlPosition + 1, 0, apiSeason.slug);
    url = paths.join('/');
  }

  if (url.startsWith('/news/')) {
    temporaryRedirect = true;
  }

  if (isCurrentSeasonWithArchiveUrl) {
    url = pathname.replace(/\/season\/[a-z0-9-]+/g, '');
    temporaryRedirect = true;
  }

  if (url !== pathname) {
    redirect(url, temporaryRedirect);
  }
};

function RedirectException(status, target) {
  this.status = status;
  this.target = target;
}

const redirect = (path, temporary) => {
  if (windowIsDefined) {
    window.CSR_Redirect = path;
  } else {
    throw new RedirectException(temporary ? 307 : 301, path);
  }
};

const getUniversalPathname = getState => {
  let pathname;
  if (windowIsDefined) {
    // get pathname on CSR
    // Note: Hook `useLocation` can not be used due to:
    // "Hooks can only be called inside of the body of a function component"
    pathname = window.location.pathname;
  } else {
    //get pathname on SSR
    pathname = getState().ssrLocation.pathname;
  }
  return pathname;
};

const checkCompetitionPathRedirect = (competitionCategory, getState) => {
  const pathname = getUniversalPathname(getState);
  const isCup = competitionCategory !== 1;
  let url = pathname;
  const newPath1 = isCup ? 'cup' : 'league';
  const paths = url.split('/');
  if (paths[1] !== newPath1) {
    paths[1] = newPath1;
  }
  url = paths.join('/');

  if (url !== pathname) {
    redirect(url, false);
  }
};

const parseLinkHeader = linkHeader => {
  const regex = /<(.*?)>; rel="(.*?)"/;
  const result = {};
  linkHeader
    ? linkHeader
        .split(/, /)
        .map(part => part.match(regex))
        .map(part => {
          result[part[2]] = part[1];
        })
    : null;
  return result;
};

export {catchDataError, checkApiRedirect, RedirectException, checkCompetitionPathRedirect, parseLinkHeader};
