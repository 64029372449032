import {api} from 'app/services/api';
import {changeHeaderText} from 'app/actions/headerActions';
import {catchDataError, checkApiRedirect, RedirectException} from 'app/helpers/apiHelpers';
import {setPartner} from 'app/actions/partnerActions';
import {changeNavBarLinks} from 'app/actions/navbar';
import {sendEventToGA4} from 'app/helpers/gtagHelpers';
import {captureSentryException} from 'app/services/sentryLogging';
import MissingAttributeFromApiException from 'app/exceptions/MissingAttributeFromApiException';

const getDistrict = districtSlug => {
  return api.get(`/districts/${districtSlug}?additionalFields=brandings,ivw`);
};

const isBlockedDistrict = slug => ['testspiele', 'relegation', 'global'].includes(slug);

// HYDRATION_FINISHED
function setHydrationFinished() {
  return {
    type: 'HYDRATION_FINISHED',
  };
}

function setAdMonetizer(data) {
  return {
    type: 'ADMONETIZER_UPDATE',
    data,
  };
}

// SELECTED_DISTRICT
function setSelectedDistrict(data) {
  return {
    type: 'SELECTED_DISTRICT_UPDATE',
    data,
  };
}

function setSelectedDistrictError(data) {
  return {
    type: 'SELECTED_DISTRICT_ERROR',
    data,
  };
}

function setDistrictHeader(dispatch, district, title, pathname) {
  if (pathname.match(/^\/((test_)?region\/|my\/matches)/)) {
    let parent = {
      name: district?.name || 'Keine Region gewählt',
      entity: 'regionChange',
      slugs: district?.region?.slug
        ? {
            regionSlug: district.region.slug,
          }
        : null,
    };

    dispatch(
      changeHeaderText({
        title,
        parent,
      })
    );
  }
}

const handleChangedDistrict = (
  dispatch,
  district,
  brandings,
  ivw_st_desktop,
  ivw_st_mobil,
  ivw_dns_desktop,
  ivw_dns_mobil,
  ivw_cp_prefix,
  currentDistrict
) => {
  const nextDistrict = district;
  // Check for currentDistrict?.region to detect api data and prevent error reports if only init state is set
  if (currentDistrict?.region?.id && currentDistrict?.slug !== nextDistrict.slug) {
    const actionType = nextDistrict?.region.id === currentDistrict?.region.id ? 'district' : 'region';
    sendEventToGA4('selected_district_changed', {
      type: actionType,
      prev_district: currentDistrict.slug,
      current_district: nextDistrict.slug,
    });
  }

  // Adjust adMonetizer based on regions for a/b tests
  // const adMonetizer = 'qm';
  // dispatch(setAdMonetizer(adMonetizer));
  dispatch(setSelectedDistrict(nextDistrict));
  dispatch(setPartner({brandings, ivw: {ivw_st_desktop, ivw_st_mobil, ivw_dns_desktop, ivw_dns_mobil, ivw_cp_prefix}}));
  dispatch(changeNavBarLinks(nextDistrict.slug));
};

// Action to sync url including district for districtPages with session store
function fetchSelectedDistrict(slug, title, pathname) {
  return function (dispatch, getState) {
    const {
      header,
      session: {district},
      ssrLocation,
    } = getState();
    // Prevent execution of "HEADER_TEXT_CHANGE" on route change on MatchCalendarDefaultPage
    if (district.id && district.slug === slug && header.title === title) {
      return;
    }
    if (district.error?.status) {
      return;
    }

    if (!slug || (district.id && district.slug === slug)) {
      setDistrictHeader(dispatch, district, title, pathname ?? ssrLocation.pathname);
      return;
    }

    return getDistrict(slug)
      .then(response => {
        const {brandings, ivw_st_desktop, ivw_st_mobil, ivw_dns_desktop, ivw_dns_mobil, ivw_cp_prefix, ...districtApi} =
          response.data;
        checkApiRedirect(districtApi.slug, slug, null, null, getState);
        setDistrictHeader(dispatch, districtApi, title, pathname ?? ssrLocation.pathname);
        handleChangedDistrict(
          dispatch,
          districtApi,
          brandings,
          ivw_st_desktop,
          ivw_st_mobil,
          ivw_dns_desktop,
          ivw_dns_mobil,
          ivw_cp_prefix
        );
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatch(setSelectedDistrictError(errorData));
        }
      });
  };
}

const isDefaultDistrictSet = getState => {
  const sessionDistrict = getState().session.district;
  return !!sessionDistrict?.slug;
};

const trySettingDefaultDistrict = (entity, dispatch, getState) => {
  if (isDefaultDistrictSet(getState)) {
    return null;
  }
  try {
    return setDefaultDistrict(entity, dispatch);
  } catch (error) {
    if (error instanceof MissingAttributeFromApiException && error.name === MissingAttributeFromApiException.name) {
      captureSentryException(error);
    } else {
      throw error;
    }
  }
};

const setDefaultDistrict = (entity, dispatch) => {
  if (!entity) {
    throw new MissingAttributeFromApiException('Default-District: entity is not set');
  }

  if (!entity.district) {
    throw new MissingAttributeFromApiException('Default-District: district in entity is not set');
  }

  const districtWithoutSlug = entity.district && !entity.district.slug;
  if (isBlockedDistrict(entity.district.slug) || districtWithoutSlug) {
    return null;
  }

  return getDistrict(entity.district.slug)
    .then(response => {
      const {brandings, ivw_st_desktop, ivw_st_mobil, ivw_dns_desktop, ivw_dns_mobil, ivw_cp_prefix, ...districtApi} =
        response.data;
      handleChangedDistrict(
        dispatch,
        districtApi,
        brandings,
        ivw_st_desktop,
        ivw_st_mobil,
        ivw_dns_desktop,
        ivw_dns_mobil,
        ivw_cp_prefix
      );
    })
    .catch(error => {
      const errorData = catchDataError(error);
      dispatch(setSelectedDistrictError(errorData));
    });
};

// QM AdSkript Ready
function updateAdsReady(data) {
  return {
    type: 'ADS_READY_UPDATE',
    data,
  };
}

// CMP Finished
function updateConsentStatus(data) {
  return {
    type: 'CONSENT_STATUS_UPDATE',
    data,
  };
}

// SSR_RENDERED
function updateSSR(data) {
  return {
    type: 'SSR_UPDATE',
    data,
  };
}

function setSsrRendered(ssrRendered) {
  return function (dispatch, getState) {
    const {session} = getState();
    if (session.ssrRendered !== ssrRendered) {
      dispatch(updateSSR(ssrRendered));
    }
  };
}

function updateVariant(data) {
  return {
    type: 'VARIANT_UPDATE',
    data,
  };
}

function updateAuthDialog(data) {
  return {
    type: 'AUTH_DIALOG_UPDATE',
    data,
  };
}

export {
  fetchSelectedDistrict,
  handleChangedDistrict,
  setSelectedDistrict,
  isBlockedDistrict,
  isDefaultDistrictSet,
  trySettingDefaultDistrict,
  setDefaultDistrict,
  setSsrRendered,
  updateAdsReady,
  updateConsentStatus,
  updateVariant,
  setHydrationFinished,
  updateAuthDialog,
};
