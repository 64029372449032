import {createTheme} from '@mui/material';
import {COLORS, FONTS, SIZES} from '@fupa/fupa-uikit';
import {zIndexBackdrop} from 'app/styles/cssConstants';
import {deDE as pickersDeDE} from '@mui/x-date-pickers';
import {deDE as gridDeDE} from '@mui/x-data-grid-pro';
import {deDE as coreDeDE} from '@mui/material/locale';

const materialTheme = createTheme(
  {
    typography: {
      fontFamily: FONTS.PTSans,
      button: {
        fontFamily: FONTS.Oswald,
        fontWeight: FONTS.regular,
      },
    },
    palette: {
      primary: {
        main: COLORS.fupaPrimary,
      },
      secondary: {
        main: COLORS.fupaSecondary,
      },
      action: {
        main: COLORS.darkGrey,
      },
      neutral: {
        main: COLORS.lightestGrey,
      },
      error: {
        main: COLORS.red,
      },
    },
    breakpoints: {
      values: {
        // MUI Default Values
        xs: 0,
        sm: 600,
        md: 970, // overwritten by value of fupa desktopLayout
        lg: 1280,
        xl: 1920,
      },
    },
    zIndex: {
      // MUI Default Values
      mobileStepper: 1000,
      speedDial: 1050,
      appBar: 1100,
      drawer: 1200,
      modal: 2147483647, // overwritten
      backdrop: zIndexBackdrop, // overwritten
      snackbar: 1400,
      tooltip: 1500,
    },
    components: {
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:hover:not(.Mui-disabled):after': {
              borderBottomColor: COLORS.fupaSecondary,
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottomColor: COLORS.fupaSecondary,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          label: {
            lineHeight: SIZES[24],
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&:hover,&:focus': {
              backgroundColor: COLORS.lightestGrey,
            },
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiRadio: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: FONTS['14'],
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          'spacing-xs-2': {
            margin: 0,
            flex: 1,
            width: 'auto',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paperWidthXs: {
            maxWidth: '30rem',
            width: '100%',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '6px 8px',
          },
          head: {
            padding: '0 8px',
          },
        },
      },
    },
  },
  pickersDeDE, // x-date-pickers translations
  gridDeDE, // x-data-grid-pro translations
  coreDeDE // core material-ui translations
);

export default materialTheme;
