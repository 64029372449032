import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {windowIsDefined} from 'app/helpers/windowIsDefined';

interface NativeInfo {
  isNativeApp: boolean;
  isFlutter: boolean;
  platform: 'android' | 'ios' | 'web';
  buildInfo: {
    platform: string;
    version: string;
    build: string;
  };
  [key: string]: any; // For additional dynamic handlers
}

interface Handler {
  name: string;
  handler: () => void;
}

const isNativeApp = (): any => {
  if (windowIsDefined) {
    // Legacy Native: iOS (Deprecated)
    if (window.webkit?.messageHandlers.native) {
      return window.webkit.messageHandlers.native;
    }
    if (window.native) {
      // Legacy Native: Android postMessage channel (Deprecated)
      // Flutter Core WebView: android & ios postMessage channel available on window.native
      return window.native;
    }
  }

  return null;
};

const generateHandler = (key: string): (() => void) => {
  if (isNativeApp()) {
    return (value?: string) => {
      const isFlutterApp = isNativeFlutterApp();
      const handlerName =
        isFlutterApp && key === 'showNativeNotificationPermissionDialog' ? 'getNativeNotificationPermission' : key;
      const message = value ? `${handlerName}:${value}` : handlerName;
      isNativeApp().postMessage(message);
    };
  }
  return () => {};
};

const availableHandlers: {[key: string]: Handler} = {
  logout: {
    name: 'logout',
    handler: generateHandler('logout'),
  },
  openDeviceSettings: {
    name: 'openDeviceSettings',
    handler: generateHandler('openDeviceSettings'),
  },

  triggerAppTrackingTransparencyDialog: {
    name: 'triggerAppTrackingTransparencyDialog',
    handler: generateHandler('triggerAppTrackingTransparencyDialog'),
  },
  shareUrl: {
    name: 'shareUrl',
    handler: generateHandler('shareUrl'),
  },
  triggerRatingDialog: {
    name: 'triggerRatingDialog',
    handler: generateHandler('triggerRatingDialog'),
  },
  setUserId: {
    name: 'setUserId',
    handler: generateHandler('setUserId'),
  },
  getNativeNotificationPermission: {
    name: 'getNativeNotificationPermission',
    handler: generateHandler('getNativeNotificationPermission'),
  },
  showNativeNotificationPermissionDialog: {
    name: 'showNativeNotificationPermissionDialog',
    handler: generateHandler('showNativeNotificationPermissionDialog'),
  },
  getDeviceToken: {
    name: 'getDeviceToken',
    handler: generateHandler('getDeviceToken'),
  },
  getDeviceInfo: {
    name: 'getDeviceInfo',
    handler: generateHandler('getDeviceInfo'),
  },
  //  FixMe: areShortcutsEnabled and createShortcut are not available via context in the current implementation
};

const NativeContext = createContext<NativeInfo | null>(null);

const isNativeFlutterAndroidApp = (): boolean => {
  // JS channel is only supported in new native Flutter app
  return typeof window !== 'undefined' && window.native_android;
};

const isNativeFlutterIOSApp = (): boolean => {
  // JS channel is only supported in new native Flutter app
  return typeof window !== 'undefined' && window.native_ios;
};

const isNativeFlutterApp = (): boolean => {
  return !!(isNativeFlutterAndroidApp() || isNativeFlutterIOSApp());
};

const isLegacyAndroidApp = (): boolean => {
  return windowIsDefined && window.native && !isNativeFlutterApp();
};

const isLegacyIOSApp = (): boolean => {
  return windowIsDefined && window.webkit?.messageHandlers?.native;
};

const isAndroid = isLegacyAndroidApp() || isNativeFlutterAndroidApp();
const isIos = isLegacyIOSApp() || isNativeFlutterIOSApp();

const getPlatform = (): 'android' | 'ios' | 'web' => {
  if (isAndroid) {
    return 'android';
  }
  if (isIos) {
    return 'ios';
  }
  return 'web';
};

interface NativeProviderProps {
  children: ReactNode;
}
export const NativeProvider = ({children}: NativeProviderProps) => {
  const [buildInfo, setBuildInfo] = useState({platform: '', version: '', build: ''});

  useEffect(() => {
    availableHandlers.getDeviceInfo.handler();
    window.setDeviceInfo = (platform, version, build) => {
      setBuildInfo({platform, version, build});
    };
  }, []);

  const nativeInfo: NativeInfo = {
    isNativeApp: !!isNativeApp(), // false for web, true for legacy native apps ios and android, true for flutter apps
    isFlutter: isNativeFlutterApp(), // false for legacy native apps ios and android, true for flutter apps
    platform: getPlatform(),
    buildInfo: buildInfo,
    ...availableHandlers,
  };

  return <NativeContext.Provider value={nativeInfo}>{children}</NativeContext.Provider>;
};

export const useNative = () => {
  return useContext(NativeContext) as NativeInfo;
};
