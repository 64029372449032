import {createSelector} from 'reselect';
import {useDispatch, useSelector} from 'react-redux';
import {selectCurrentUser, selectSsrRendered} from 'app/hooks/reduxCreateSelectorHooks';
import {useEffect, useRef, useState} from 'react';
import {updateHistory} from 'app/actions/historyActions';
import {useHistory} from 'react-router';
import {updatePullToRefresh} from 'app/actions/pullToRefreshActions';

const selectHistoryData = createSelector(
  state => state.dataHistory,
  (_, key) => key ?? 'undefined',
  (dataHistory, key) => dataHistory.find(history => history.key === key)
);

const getLocationKey = () => {
  const isSSR = useSelector(selectSsrRendered);
  const history = useHistory();
  const replaceUrl = history.action === 'REPLACE';
  // Need to check history change by location replace without changing state (no data fetching), e.g. ClubMatchesPage
  return {key: isSSR && !replaceUrl ? 'undefined' : history.location.key, action: history.action};
};

const useRestoreData = storeName => {
  const {key} = getLocationKey();
  const pullToRefresh = useSelector(state => state.pullToRefresh);
  const restoreData = useSelector(state => selectHistoryData(state, key));
  if (pullToRefresh) {
    return {restoredData: undefined, key};
  } else {
    return {restoredData: restoreData?.[storeName], key};
  }
};

const useFetchData = (
  state,
  loadData,
  storeName,
  hasStreamFilter = false,
  waitForFirebase = false,
  preventHistoryUpdate = false
) => {
  const dispatchRedux = useDispatch();
  const {initializing} = useSelector(selectCurrentUser);
  const startFetching = waitForFirebase ? !initializing : true;

  const {key, action} = getLocationKey();
  // useRef to store state and key as mutable values that exist for the lifetime of the component
  const stateRef = useRef({key, state});

  useEffect(() => {
    if (startFetching) {
      loadData();
    }
  }, [startFetching]);

  useEffect(() => {
    // Write to history on unmount page
    return () => {
      if (!preventHistoryUpdate) {
        dispatchRedux(updatePullToRefresh(false));
        dispatchRedux(updateHistory(stateRef.current.key, {store: storeName, data: stateRef.current.state}));
      }
    };
  }, [preventHistoryUpdate]);

  // keep the ref's value updated whenever the state changes
  useEffect(() => {
    // Update the history key only if it changes while the page is mounted due to replace url or new push state by stream filters
    // Note: On back navigation, key has already the value of the new location on unmounting the current page
    // e.g. ClubMatchesPage - back to - ClubNewsPage => location.key: ClubNewsPage, data to store: ClubMatchesPage
    const newKey = action === 'REPLACE' || (action === 'PUSH' && hasStreamFilter) ? key : stateRef.current.key;
    stateRef.current = {key: newKey, state};
  }, [state, key]);
};

/**
 * useEffect that does not run on mount, but only on update when dependecies change
 * Possibility to also add a unmount-call by saving the effect() return-value
 * @param {func} effect Function to run on update
 * @param {array | undefined} dependencies changing values the useEffect should respond to
 */
const useEffectAfterMount = (effect, dependencies) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      // run effect whenever there is an update on the dependecies
      effect();
    } else {
      // set didMount on first call when component is mounting
      didMount.current = true;
    }
  }, dependencies);
};

const useDataFetchFinished = isFetchingData => {
  const isSSR = useSelector(selectSsrRendered);
  const history = useHistory();
  const backNavigation = history?.action === 'POP';
  const [fetchingApiDataFinished, setFetchingApiDataFinished] = useState(isSSR || backNavigation);
  const [fetchingStarted, setFetchingStarted] = useState(isFetchingData);

  useEffect(() => {
    // set variable as state to check for data fetching has finished
    setFetchingStarted(isFetchingData);
    const compareState = fetchingStarted !== isFetchingData;
    if (compareState && !isFetchingData) {
      setFetchingApiDataFinished(true);
    }
  }, [isFetchingData]);

  return fetchingApiDataFinished;
};

export {useRestoreData, useFetchData, useEffectAfterMount, useDataFetchFinished};
