import is from '@sindresorhus/is';

export enum StorageType {
  localStorage = 'localStorage',
  sessionStorage = 'sessionStorage',
}

type StorageItem = {
  value: any;
  expiresAt: string;
};

const ONE_SECOND_IN_MS = 1000;
const ONE_MINUTE_IN_S = 60;
const ONE_HOUR_IN_MINUTES = 60;
const ONE_DAY_IN_MINUTES = ONE_HOUR_IN_MINUTES * 24;

export default abstract class WebStorageManager {
  protected abstract storageType: StorageType;

  private storageAvailable = (type: StorageType) => {
    let storage: any;
    try {
      storage = window[type];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0
      );
    }
  };

  private isExpired(item: string) {
    try {
      const parsedItem: StorageItem = JSON.parse(item);
      if (!parsedItem.expiresAt) {
        return false;
      }
      const now = this.getCurrentTimestamp();
      const expiresAt = new Date(parsedItem.expiresAt).getTime() / ONE_SECOND_IN_MS;
      return now > expiresAt;
    } catch (e) {
      return false;
    }
  }

  private getCurrentTimestamp() {
    return Math.floor(Date.now() / ONE_SECOND_IN_MS);
  }

  private createExpirationDate(expiresInMinutes: number) {
    const expiresAt = (this.getCurrentTimestamp() + expiresInMinutes * ONE_MINUTE_IN_S) * ONE_SECOND_IN_MS;
    return new Date(expiresAt).toISOString();
  }

  setItem(key: string, value: any, expiresInOrAt: number | Date = ONE_DAY_IN_MINUTES) {
    if (this.storageAvailable(this.storageType)) {
      let expiresAt: string;
      if (is.number(expiresInOrAt)) {
        expiresAt = this.createExpirationDate(expiresInOrAt);
      } else {
        if (expiresInOrAt < new Date()) {
          throw new Error('Expiration date must be in the future');
        }
        expiresAt = expiresInOrAt.toISOString();
      }

      const stringifiedValue = JSON.stringify({value, expiresAt});
      window[this.storageType].setItem(key, stringifiedValue);
      window.dispatchEvent(new CustomEvent('storageChanged', {detail: {key, value}}));
    }
  }

  private getItemFromStorage(key: string): string | null {
    if (this.storageAvailable(this.storageType)) {
      return window[this.storageType].getItem(key);
    }
    return null;
  }

  private getItemValue(item: string) {
    try {
      const parsedItem: StorageItem = JSON.parse(item);
      return parsedItem.value;
    } catch (e) {
      return item;
    }
  }

  getItem(key: string) {
    const item = this.getItemFromStorage(key);
    if (!item) {
      return null;
    }
    if (this.isExpired(item)) {
      this.removeItem(key);
      return null;
    }
    return this.getItemValue(item);
  }

  removeItem(key: string) {
    if (this.storageAvailable(this.storageType)) {
      window[this.storageType].removeItem(key);
    }
  }

  clearExpired() {
    if (this.storageAvailable(this.storageType)) {
      Object.keys(window[this.storageType]).map(key => {
        const item = this.getItemFromStorage(key);
        if (item && this.isExpired(item)) {
          this.removeItem(key);
        }
      });
    }
  }
}
