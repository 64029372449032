import {generateUniqueTemplateKey} from 'app/helpers/gtm';
import {onLCP, onFID, onCLS, onINP} from 'web-vitals/attribution';
import {sendEventToGA4} from 'app/helpers/gtagHelpers';
import {windowIsDefined} from 'app/helpers/windowIsDefined';

/**
 * Report Web-Vitals: Uses web-vitals library to extract Web-Vitals measurement from real user and returns measures back to callback function
 * reportable type: 'CLS' | 'FCP' | 'FID' | 'LCP' | 'TTFB' -> report with handler (e.g. onCLS())
 * @param onPerfEntry Callback function to log web-vitals, default is to send the results to google analytics
 */
const reportWebVitals = (onPerfEntry = sendToAnalytics) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    onCLS(onPerfEntry, {reportAllChanges: true});
    onFID(onPerfEntry);
    onLCP(onPerfEntry);
    onINP(onPerfEntry);
  }
};

let timeout;

const sendToAnalytics = report => {
  const {name, rating, attribution, id, value, delta} = report;
  const pathname = windowIsDefined ? window.location.pathname + window.location.search + window.location.hash : '/';
  const {template} = generateUniqueTemplateKey(pathname);

  const ga4EventParams = {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    content_group: template,
    // Custom params:
    metric_id: id, // id should be used to aggregate the events
    metric_value: value,
    metric_delta: delta,
    metric_rating: rating,
    fupa_tech_metric: 'web_vitals',
  };

  let debugTarget;
  switch (name) {
    case 'CLS':
      debugTarget = attribution?.largestShiftTarget ?? '';
      break;
    case 'FID':
    case 'INP':
      debugTarget = attribution?.eventTarget ?? '';
      break;
    case 'LCP':
      debugTarget = attribution?.element ?? '';
      break;
  }

  ga4EventParams.debug_target = debugTarget;

  if (rating !== 'good') {
    console.log(`${name}:`, report);
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      sendEventToGA4(name, ga4EventParams);
    }, 1000);
  }
};

export {reportWebVitals};
