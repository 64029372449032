import React, {ReactNode, ForwardedRef} from 'react';
import styled from 'styled-components';
import {Link, LinkProps, NavLink, NavLinkProps} from 'react-router-dom';
import is from '@sindresorhus/is';

interface SCLinkOnComponentProps extends LinkProps {
  decoration?: string;
  color?: string;
  enablehover?: boolean;
  hovercolor?: string;
}

const SCLinkOnComponent = styled(Link)<SCLinkOnComponentProps>`
  display: block;
  text-decoration: ${props => (props.decoration ? props.decoration : 'none')};
  color: ${props => (props.color ? props.color : 'inherit')};
  cursor: pointer;

  &:focus,
  &:hover {
    text-decoration: ${props => (props.decoration ? props.decoration : 'none')};
    background-color: ${props =>
      props.enablehover ? (props.hovercolor ? props.hovercolor : 'rgba(215,223,230,0.25)') : 'transparent'};
  }

  &:visited,
  &:link,
  &:active {
    text-decoration: ${props => (props.decoration ? props.decoration : 'none')};
  }
`;

const NOFOLLOW = 'nofollow';
const FOLLOW = 'follow';

const linkRelConfig: Record<string, string> = {
  player: FOLLOW,
  match: NOFOLLOW,
  team: NOFOLLOW,
  tv: NOFOLLOW,
  photos: NOFOLLOW,
  club: FOLLOW,
  matches: FOLLOW,
};

export interface LinkOnComponentProps {
  children: ReactNode;
  to?: string | {pathname: string};
  rel?: string;
  decoration?: string;
}

const LinkOnComponent = React.forwardRef(({children, ...props}: LinkOnComponentProps, ref: ForwardedRef<Link>) => {
  const pathname = is.string(props.to) ? props.to : props.to?.pathname;
  const isBlocked = pathname
    ? pathname.match(/\/(region|league)\/($|(testspiele|relegation|global|uebergangsliga|ohne-liga-teams))/)
    : null;

  const isInterstitialEnabled = pathname?.startsWith('/photos/') || pathname?.startsWith('/news/');

  if (!pathname || isBlocked) {
    return <>{children}</>;
  }

  const pathParams = pathname.split('/');
  pathParams.shift();
  const entity = pathParams[0];
  let relMeta = linkRelConfig[entity] || props.rel || 'follow';
  const hasSeason = pathParams[2] === 'season';

  if (hasSeason) {
    relMeta = 'nofollow';
  }

  return (
    <SCLinkOnComponent ref={ref} rel={relMeta} data-google-interstitial={isInterstitialEnabled} {...props}>
      {children}
    </SCLinkOnComponent>
  );
});

const FPNavLink = React.forwardRef(({children, ...props}: NavLinkProps, ref: ForwardedRef<NavLink>) => {
  return (
    <NavLink data-google-interstitial='false' ref={ref} {...props}>
      {children}
    </NavLink>
  );
});

const FPLink = React.forwardRef(({children, ...props}: LinkProps, ref: ForwardedRef<Link>) => {
  return (
    <Link data-google-interstitial='false' ref={ref} {...props}>
      {children}
    </Link>
  );
});

const FPHTMLLink = ({children, ...props}) => {
  return (
    <a data-google-interstitial='false' {...props}>
      {children}
    </a>
  );
};

export {LinkOnComponent, FPNavLink, FPLink, FPHTMLLink};
