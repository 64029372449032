import React, {useEffect} from 'react';
import styled from 'styled-components';
import {SIZES, Typography} from '@fupa/fupa-uikit';
import {Content} from 'app/styles/content';
import {Helmet} from 'react-helmet-async';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {Button} from '@mui/material';
import {Announcement, Replay} from '@mui/icons-material';
import {sendEventToGA4} from 'app/helpers/gtagHelpers';
import {generateUniqueTemplateKey} from 'app/helpers/gtm';
import {ssrRequestContext} from 'app/ssrContext';

const Wrapper = styled(Content)<{top: string}>`
  &&& {
    padding: ${props => (props.top ? props.top : SIZES[72])} ${SIZES['16']};
    height: calc(100vh - ${props => (props.top ? props.top : SIZES[72])});
    margin: auto;
  }
`;

const Text = styled(Typography)`
  &&& {
    text-align: center;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: ${SIZES['32']};
`;

function getConnectionType(): string {
  return 'connection' in navigator && navigator.connection?.['effectiveType']
    ? navigator.connection['effectiveType'] ?? 'undefined'
    : 'undefined';
}

const NetworkErrorPage = ({top}) => {
  if (typeof window === 'undefined') {
    ssrRequestContext.cacheControl = 'no-cache, no-store';
  }
  useEffect(() => {
    const pathname = window.location.pathname + window.location.search + window.location.hash;
    const {template} = generateUniqueTemplateKey(pathname);
    const connectionType = getConnectionType();
    sendEventToGA4('response_timeout', {
      fupa_tech_metric: 'response_timeout',
      content_group: template,
      fupa_connection_status: connectionType,
    });
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Wrapper id='network-error' top={top}>
      <Container>
        <Helmet title={`Netzwerk Fehler${TITLE_POSTFIX}`} />
        <Announcement sx={{fontSize: '72px', marginBottom: SIZES['32']}} color='warning' />
        <Text variant='headline2' component='h1'>
          Zeitüberschreitung der Anfrage
        </Text>
        <Text variant='subheading' component='p'>
          Die Seite benötigt zu lange zum Laden. Bitte überprüfe deine Internetverbindung und versuche es erneut.
        </Text>
        <Button
          sx={{marginTop: SIZES['32']}}
          color='secondary'
          onClick={handleReload}
          startIcon={<Replay color='inherit' />}
          variant='contained'
          size='small'>
          Erneut Versuchen
        </Button>
      </Container>
    </Wrapper>
  );
};

export default NetworkErrorPage;
