const initialData = {
  ytScriptLoaded: false,
};

export const embedReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'UPDATE_YT_SCRIPT_LOADED':
      return {...state, ytScriptLoaded: action.data};
    default:
      return state;
  }
};
