import {COLORS, FONTS, SIZES} from '@fupa/fupa-uikit';
import React from 'react';
import styled, {css} from 'styled-components';
import {LinkOnComponent} from 'app/components/links/link';

const Container = styled(LinkOnComponent)<{selected: boolean}>`
  display: flex;
  flex: 1 20%;
  height: ${SIZES['44']};
  align-items: center;
  flex-direction: column;
  color: ${COLORS.darkerGrey};
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(180, 180, 180, 0.1);

  ${({selected}) =>
    selected
      ? css`
          color: ${COLORS.fupaSecondary};
          ${Circle} {
            background-color: ${COLORS.fupaPrimary};
          }
        `
      : css`
          :hover,
          :focus {
            background-color: ${COLORS.lighterGrey};
            ${Circle} {
              background-color: ${COLORS.lightGrey};
            }
          }
        `}

  // Desktop Styles
  ${({theme, selected}) => theme.desktopLayout`
    color: initial;
    justify-content: center;
    height: ${SIZES['48']};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin: 0 0.375rem;

    ${
      selected
        ? css`
            ${Circle} {
              background-color: ${COLORS.lighterGrey};
            }
            ${Label} {
              color: ${COLORS.fupaSecondary};
            }
          `
        : css`
            :hover,
            :focus {
              background-color: transparent;
              ${Circle} {
                background-color: ${COLORS.fupaSecondaryFocused};
              }
              ${Label} {
                display: flex;
              }
            }
          `
    };
  `}
`;

const Circle = styled.div`
  display: flex;
  border-radius: 100%;
  width: ${SIZES['28']};
  height: ${SIZES['28']};
  background-color: ${COLORS.lighterGrey};
  justify-content: center;
  align-items: center;
  ${props => props.theme.desktopLayout`
    width: ${SIZES['48']};
    height: ${SIZES['48']};
    background-color: rgba(256, 256, 256, 0.1);
  `}
`;

const Label = styled.span`
  font-family: ${FONTS.PTSans};
  font-size: ${FONTS['12']};
  ${props => props.theme.desktopLayout`
  color: ${COLORS.white};
  align-items: center;
  justify-content: center;
  position: absolute;
  padding-top: 1rem;
  `};
`;

interface NavBarItemProps {
  isSelected: boolean;
  link: string;
  onClick: () => void;
  componentType: any;
  children?: React.ReactElement | React.ReactElement[];
  label: string;
  follow: boolean;
}

export default function NavBarItem({
  isSelected,
  link,
  onClick,
  componentType,
  children,
  label,
  follow,
}: NavBarItemProps) {
  return (
    <Container
      selected={isSelected}
      to={link}
      onClick={onClick}
      as={componentType}
      rel={follow ? 'follow' : 'nofollow'}>
      <Circle>{children}</Circle>
      <Label>{label}</Label>
    </Container>
  );
}
