import React, {forwardRef, useMemo} from 'react';
import {COLORS, Image, ImageEntity, ImageSrc} from '@fupa/fupa-uikit';
import {Person} from '@mui/icons-material';
import styled from 'styled-components';

const AvatarContainer = styled.div<{dim: string; active: boolean | undefined}>`
  box-sizing: content-box;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  width: ${props => props.dim};
  height: ${props => props.dim};
  border-radius: 50%;
  border: ${props => (props.active ? `2px solid ${COLORS.fupaSecondary}` : `2px solid ${COLORS.white}`)};
  background-color: ${COLORS.grey};
  ${props => props.theme.desktopLayout`
    border: ${props.active ? `2px solid ${COLORS.fupaSecondary}` : `2px solid ${COLORS.white}`};
  `}
`;

export const LoadingSkeleton = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLORS.lightGrey};
  background: linear-gradient(110deg, ${COLORS.lightestGrey} 15%, ${COLORS.lightGrey} 50%, ${COLORS.lightestGrey} 85%);
  border-radius: 50%;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

interface Props {
  width: number;
  src?: string;
  active?: boolean;
  loading?: boolean;
  alt?: string;
}

export type Ref = HTMLDivElement;

const Avatar = forwardRef<Ref, Props>(({width, src, active, loading, ...props}, ref) => {
  const dim = width / 16 + 'rem';
  const imageSrc = useMemo((): ImageSrc => {
    if (src) {
      const pathArray = src.split('/');
      pathArray.pop();
      const path = pathArray.join('/');
      return {
        path: path + '/',
        description: null,
        source: null,
        svg: false,
      };
    }
    return {
      path: '',
      description: null,
      source: null,
      svg: false,
    };
  }, [src]);
  const avatar = src ? (
    <Image
      entity={ImageEntity.User}
      height={width}
      width={width}
      src={imageSrc}
      alt={props.alt ?? 'Nutzeroptionen öffnen'}
      ratio={'1:1'}
      circle={true}
    />
  ) : loading ? (
    <LoadingSkeleton />
  ) : (
    <Person style={{color: COLORS.darkerGrey, margin: 'auto'}} />
  );

  return (
    <AvatarContainer ref={ref} {...props} active={active} dim={dim}>
      {avatar}
    </AvatarContainer>
  );
});

export {Avatar};
